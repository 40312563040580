import React, { Component } from "react";
// import { Link } from "gatsby";
import logoDark from "../assets/images/eatella-logo.png";
import Texts from "../../../utils/lang-utils";
import urls from "../../../areas/main/assets/urls";

export default class NavOne extends Component {

  constructor() {
    super();
    this.state = {
      sticky: false,
      promoFinished: false
    };

  }
  componentDidMount() {

    // if((navigator.languages || [navigator.language]).some(l => l.split("-")[0] === "ru")) {
    //   if(!window.location.pathname.includes("/ru")) {

    //     window.location.href = "/ru" + window.location.pathname;
    //   }
    // }
      // left: 37, up: 38, right: 39, down: 40,
      // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
      var keys = {37: 1, 38: 1, 39: 1, 40: 1};

      function preventDefault(e) {
        e.preventDefault();
        handleScroll();
        enableScroll();
      }

      function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
          preventDefault(e);
          handleScroll();
          enableScroll();
          return false;
        }
      }

      // modern Chrome requires { passive: false } when adding event
      var supportsPassive = false;
      try {
        window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
          get: function () { return supportsPassive = true; } 
        }));
      } catch(e) {}

      var wheelOpt = supportsPassive ? { passive: false } : false;
      var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

      // call this to Disable
      function disableScroll() {
        window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
        window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', preventDefaultForScrollKeys, false);
      }

      // call this to Enable
      function enableScroll() {
        window.setTimeout(() => {
          window.removeEventListener('DOMMouseScroll', preventDefault, false);
          window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
          window.removeEventListener('touchmove', preventDefault, wheelOpt);
          window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
        }, 1000);
      }

      const handleScroll = () => {

        if (window.scrollY > 70) {
          
          this.setState({
            sticky: true
          });
        } else if (window.scrollY < 70) {
          this.setState({
            sticky: false
          });
        }
    
    
        if(!document.body.classList.contains("scrolled")) {
          document.body.classList.add("scrolled");
          window.setTimeout(function() {
            document.querySelector(".video video") && document.querySelector(".video video").play();          
              
          }, 2500);
        }
      };

      if (this.props.catchScroll && window.innerWidth >= 1200) {
        disableScroll();
      }
      window.addEventListener("scroll", handleScroll);

    //Mobile Menu
    this.mobileMenu();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }


  

  mobileMenu = () => {
    //Mobile Menu Toggle
    let mainNavToggler = document.querySelector(".menu-toggler");
    let mainNav = document.querySelector(".main-navigation");

    mainNavToggler.addEventListener("click", function () {
      mainNav.style.display =
        mainNav.style.display !== "block" ? "block" : "none";
    });
  };


  



  render() {
    return (
      <header className="site-header site-header__header-one">
        <nav
          className={`navbar navbar-expand-lg navbar-light header-navigation stricky ${
            this.state.sticky ? "stricked-menu stricky-fixed" : ""
          }`}
        >
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <a className="navbar-brand" href="/">
                <img
                  src={logoDark}
                  className="main-logo"
                  width="159"
                  alt="alter text"
                />
              </a>
              <button className="menu-toggler">
                <span className="fa fa-bars"></span>
              </button>
            </div>
            <div className="main-navigation">
              <ul className=" one-page-scroll-menu navigation-box">
                
                
                <li className="scrollToLink">
                  <a href={urls.qrMenu}>{Texts.Nav.QrMenu}</a>
                </li>
                <li className="scrollToLink">
                  <a href={urls.qrOrdering}>{Texts.Nav.QROrdering}</a>
                </li>
                <li className="scrollToLink">
                  <a href={urls.apps}>{Texts.Nav.Apps}</a>
                </li>
                <li className="scrollToLink">
                  <a href={urls.pricing}>{Texts.Nav.Pricing}</a>
                </li>
              </ul>
            </div>
            <div className="right-side-box">
              <a className="thm-btn header__cta-btn" href={urls.onboarding}>
                <span>{Texts.Nav.CTA}</span>
              </a>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
